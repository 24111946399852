<template>
  <div class="container pb-5">

    <h2 class="pt-4">VRTag: Application Version</h2>

    <div class="py-2">
      This app is a self-updating PWA.<br>
    </div>

    <div class="py-2">
      App: {{ location }}<br>
      App version: {{ this.app.version }}<br> 
      Connected to: {{ this.app.api.server }}
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      location: window.location.host,
    }
  }
}
</script>