<template>
  <div class="container pb-5">

    <h2 class="pt-4">Links into this app</h2>

    <div class="py-2">
      To be implemented.
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  methods: {
  }
}
</script>