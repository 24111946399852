<template>
  <div class="login pt-1 pb-5">
    <form autocomplete="off">
      <h3 class="mt-4 mb-0 text-center">Login</h3>

      <div v-if="hasServer" class="m-0 p-0 mb-3 text-center small tiny">
        {{ this.server }}
      </div>
      <div v-else class="form-floating mt-4 mb-3">
        <input
          v-model="server"
          type="text"
          class="form-control"
          id="server"
          autocapitalize="none"
          autocomplete="nope"
          autocorrect="off"
          spellcheck="false"
          placeholder="Server name (without https://)"
          v-on:blur="
            this.server = this.server
              .toLowerCase()
              .replace(new RegExp(' ', 'g'), '')
          "
        />
        <label for="server">Server name (without https://)</label>
      </div>

      <div class="form-floating mb-3">
        <input
          v-model="username"
          type="email"
          class="form-control"
          id="username"
          autocapitalize="none"
          autocomplete="nope"
          autocorrect="off"
          spellcheck="false"
          placeholder="Username"
        />
        <label for="username">Username</label>
      </div>

      <div class="form-floating mb-3">
        <input
          v-model="password"
          type="password"
          class="form-control"
          id="password"
          autocapitalize="none"
          autocomplete="nope"
          autocorrect="off"
          spellcheck="false"
          placeholder="Password"
        />
        <label for="password">Password</label>
      </div>

      <p id="login_feedback" class="info tiny">
        {{ feedback }}
      </p>

      <button
        @click="doLogin()"
        type="button"
        class="btn btn-sm btn-primary me-3"
      >
        <i class="fa fa-sign-in-alt"></i> Login
      </button>
    </form>
  </div>
</template>

<script>
//import router from "@/router";

export default {
  data() {
    return {
      server: "",
      username: "",
      password: "",
      feedback: "",
    };
  },

  computed: {
    hasServer() {
      if (this.app.api.server) {
        return this.app.api.server != "";
      } else {
        return false;
      }
    },
  },

  created() {
    this.server = this.app.api.server || "";
    if (this.app.user) {
      this.username = this.app.user.username || "";
    }
  },

  methods: {
    doLogin() {
      this.feedback = "One moment please...";
      this.app
        .login(
          this.server.toLowerCase().replace(new RegExp(" ", "g"), ""),
          this.username,
          this.password
        )
        .then(
          (result) => {
            // The result is true (success) or false (unsuccessful)
            if (result) {
              this.$router.replace("/");
            } else {
              this.feedback = "Could not login. Check your credentials and try again.";
            }
          },
          (error) => {
            console.error(error);
            this.feedback = "Could not login because of an error. Check your credentials and try again.";
          }
        );
    },
  },
};
</script>