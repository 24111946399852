<template>
  <div class="container pb-5">
    <div class="pt-2">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link px-2 active" @click="doSelect('app')" id="nav-app-tab" data-bs-toggle="tab" data-bs-target="#nav-app" type="button" role="tab" aria-controls="nav-app" aria-selected="true">App</button>
          <button class="nav-link px-2" @click="doSelect('log')" id="nav-log-tab" data-bs-toggle="tab" data-bs-target="#nav-log" type="button" role="tab" aria-controls="nav-log" aria-selected="false">Log</button>
          <button class="nav-link px-2" @click="doSelect('error')" id="nav-error-tab" data-bs-toggle="tab" data-bs-target="#nav-error" type="button" role="tab" aria-controls="nav-error" aria-selected="false">Errors</button>
          <button class="nav-link px-2" @click="doSelect('localstorage')" id="nav-localstorage-tab" data-bs-toggle="tab" data-bs-target="#nav-localstorage" type="button" role="tab" aria-controls="nav-localstorage" aria-selected="false">Storage</button>
          <button class="nav-link px-2" @click="doSelect('indexeddb')" id="nav-indexeddb-tab" data-bs-toggle="tab" data-bs-target="#nav-indexeddb" type="button" role="tab" aria-controls="nav-indexeddb" aria-selected="false">IDB</button>
          <button class="nav-link px-2" @click="doSelect('cache')" id="nav-cache-tab" data-bs-toggle="tab" data-bs-target="#nav-cache" type="button" role="tab" aria-controls="nav-cache" aria-selected="false">Cache</button>
          <button class="nav-link px-2" @click="doSelect('memory')" id="nav-memory-tab" data-bs-toggle="tab" data-bs-target="#nav-memory" type="button" role="tab" aria-controls="nav-memory" aria-selected="false">Memory</button>
        </div>
      </nav>

      <div class="tab-content tiny" id="nav-tabContent">
        <div class="tab-pane fade py-3 active show" id="nav-app" role="tabpanel" aria-labelledby="nav-app-tab">
          <pre>{{application}}</pre>
          <button @click="debugSend" type="button" id="debugSend" class="btn btn-primary btn-sm">Send to support</button>
        </div>
        <div class="tab-pane fade py-3" id="nav-log" role="tabpanel" aria-labelledby="nav-log-tab">
          <pre>{{(app_console ? app_console.log : '')}}</pre>
        </div>
        <div class="tab-pane fade py-3" id="nav-error" role="tabpanel" aria-labelledby="nav-error-tab">
          <pre>{{(app_console ? app_console.error : '')}}</pre>
        </div>
        <div class="tab-pane fade py-3" id="nav-localstorage" role="tabpanel" aria-labelledby="nav-localstorage-tab">
          <pre v-for="key in localstorage" :key="key" :id="'localstorage.' +key" class="mt-0 mb-2" @click="inspectLocalStorage(key)">{{key}}</pre>
        </div>
        <div class="tab-pane fade py-3" id="nav-indexeddb" role="tabpanel" aria-labelledby="nav-indexeddb-tab">
          <pre v-for="key in indexeddb" :key="key" :id="'indexeddb.' +key" class="mt-0 mb-2" @click="inspectIndexedDB(key)">{{key}}</pre>
        </div>
        <div class="tab-pane fade py-3" id="nav-cache" role="tabpanel" aria-labelledby="nav-cache-tab">
          <pre v-for="key in cache" :key="key" :id="'cache.' +key" class="mt-0 mb-2" @click="inspectIndexedDB(key)">{{key}}</pre>
        </div>
        <div class="tab-pane fade py-3" id="nav-memory" role="tabpanel" aria-labelledby="nav-memory-tab">
          <h6>Memory</h6>
          <pre class="m-0">Total JS heap: {{memory.totalJSHeapSize}}</pre>
          <pre class="m-0">Used JS heap: {{memory.usedJSHeapSize}}</pre>
          <pre class="m-0">Heap size limit: {{memory.jsHeapSizeLimit}}</pre>

          <h6 class="mt-3">Local storage</h6>
          <pre class="m-0">Objects: {{localstorage_info}}</pre>
          <pre class="m-0">Total size: {{localstorage_size}}</pre>

          <h6 class="mt-3">Indexed DB</h6>
          <pre class="m-0">Objects: {{indexeddb_info}}</pre>
          <pre class="m-0">Total size: {{indexeddb_size}}</pre>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col-6">
        <input type="text" id="debugFind" class="form-control form-control-sm" @input="debugFind()" placeholder="Search.." />
      </div>
    </div>
  </div>

  <div class="modal fade" id="debugModal" tabindex="-1" aria-labelledby="debugModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="debugModalLabel">...</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body overflow-auto" style="max-height:70vh">
          <div class="description tiny p-1 py-3" id="debugModalDescription">...</div>
        </div>
        <div class="modal-footer">
          <template v-if="allowRecordReupload">
            <button type="button" class="btn btn-secondary" @click="resendRecord()">Upload again</button>
          </template>
          <button type="button" class="btn btn-secondary me-auto" @click="removeEntry()">Delete</button>
          <button type="button" id="debugModalOK" class="btn btn-primary" data-bs-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  data() {
    return {
      modal: null,
      type: null, // Remember type info type that we were looking at when trying to delete it
      key: null, // Remember the key of what we were looking at when trying to delete it
      app_console: window.app_console,
      indexeddb: this.indexeddb,
      localstorage: this.app.storage.keys().sort(),
      indexeddb_size: this.indexeddb_size,
      localstorage_size: this.localstorage_size,
      cache: this.cache,
      memory: console.memory || {
        totalJSHeapSize: 'N/A',
        usedJSHeapSize: 'N/A',
        jsHeapSizeLimit: 'N/A'
      },
      indexeddb_info: this.indexeddb_info,
      localstorage_info: this.localstorage_info,
      allowRecordReupload: false,
    }
  },

  computed: {
    application() {
      // Make a copy of application
      let result = JSON.parse(JSON.stringify(this.app));
      return result;
    }
  },

  beforeCreate() {
    let self = this;

    // Get a list of the stored objects, and do some counting
    window.application.storage.blobs().then( data => {
      self.indexeddb = data;

      self.indexeddb_info = {
        photos: 0,
        modules: 0,
      }
      for( let object of data ) {
        object = object.split('.');
        switch( object[0] ) {
          case 'photos':
            self.indexeddb_info.photos += 1;
            break;
          case 'modules':
            self.indexeddb_info.modules += 1;
            break;
          default:
            // Ignore for now
        }
      }
    });

    // Get an estimate of the size of indexeddb
    try {
      navigator.storage.estimate().then( (data) => {
        self.indexeddb_size = data
      });
    } catch(error) {
      console.error(error);
      self.indexeddb_size = 'Property not available';
    }

    // Get an estimate of the size of localstorage (localStorage does UTF-16, blob does UTF-8, so estimate)
    self.localstorage_size = new Blob(Object.values(localStorage)).size *2;

    // Calculate the info for localStorage
    self.localstorage_info = {
      info: 0,
      forms: 0,
      posts: 0,
      other: 0,
    }
    for( let key of self.app.storage.keys() ) {
      key = key.split('.');
      switch( key[0] ) {
        case 'info':
          self.localstorage_info.info += 1;
          break;
        case 'forms':
          self.localstorage_info.forms += 1;
          break;
        case 'created':
          self.localstorage_info.posts += 1;
          break;
        default:
          self.localstorage_info.other += 1;
      }
    }

    // Get the cached urls
    // https://stackoverflow.com/a/61254111/4177565
    self.cache_info = ['asdf'];
    window.application.getCachedUrls('general').then( urls => {
      self.cache = urls;
    });
  },

  mounted() {
    // Initialize the modal
    this.modal = new Modal(document.getElementById('debugModal'), {
      backdrop: true,
    });
  },

  methods: {
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },

    debugSend() {
      var content = {
        console: window.app_console,
        localstorage: {},
        memory: {
          localstorage: this.localstorage_info,
          localstorage_size: this.localstorage_size,
          indexeddb: this.indexeddb_info,
          indexeddb_size: this.indexeddb_size,
        },
        application: window.application,
      };

      for( var key of this.app.storage.keys() ) {
        if( key.substring( 0, 5 ) != 'info.' && key.substring( 0, 6 ) != 'forms.' ) {
          content.localstorage[key] = this.app.storage.get(key);
        }
      }

      this.app.api.doPost('post_support', 'support', content).then((result) => {
        if (result) {
          this.app.alert( 'Debug information has been sent.' );
        } else {
          this.app.alert( 'An error occurred and debug information could not be sent.' );
        }
      });
    },

    inspectLocalStorage(key) {
      console.log('debug.inspectLocalStorage(' +key +')');

      // Load the info
      let content = this.app.storage.get(key);
      if( typeof content == 'object' ) {
        content = JSON.stringify(content, null, 4);
      }
      document.getElementById('debugModalLabel').innerText = key;
      document.getElementById('debugModalDescription').innerText = content;

      // Memorize it so we can delete it if necessary
      this.type = 'localstorage';
      this.key = key;

      // If this is a record, allow it to be re-uploaded if it already is
      this.allowRecordReupload = false;
      if( key.substr(0,8) == 'created.') {
        let record = this.app.storage.get(key);
        if( Object.prototype.hasOwnProperty.call( record, 'status' )) {
          if( record.status != 'local' ) {
            this.allowRecordReupload = true;
          }
        }
      }

      // Show the modal with the overview
      this.modal.show();
    },

    inspectIndexedDB(key) {
      console.log('debug.inspectIndexedDB(' +key +')');

      // Load the info
      document.getElementById('debugModalLabel').innerText = key;
      document.getElementById('debugModalDescription').innerHTML = '<img class="img-fluid" src="" id="' +key +'" alt="">';
      this.app.storage.loadBlob( key, key );

      // Memorize it so we can delete it if necessary
      this.type = 'indexeddb';
      this.key = key;

      // Show the modal with the overview
      this.modal.show();
    },

    removeEntry() {
      console.log('debug.removeEntry');
      let self = this;

      try {
        if( confirm( 'Er du sikker?')) {
          switch( this.type ) {
            case 'localstorage':
              self.app.storage.remove( this.key );
              break;
            case 'indexeddb':
              self.app.storage.removeBlob( this.key );
              break;
            default:
              alert( 'Unknown type; expected indexeddb or localstorage' );
              break;
          }
          document.getElementById( this.type +'.' +this.key ).remove();
          this.modal.hide();
        }
      } catch(error) {
        console.error(error);
      }
    },

    debugFind() {
      console.log('debug.debugFind');
      let els = document.querySelectorAll('#nav-' +this.context +' pre')
      let el = document.getElementById('debugFind');
      let reg = new RegExp( '.*' +el.value +'.*');
      els.forEach((e)=>{
        if(e.id) {
          e.style.display = (e.id.match(reg) ? '':'none');
        }
      });
    },

    doSelect(e) {
      console.log('debug.doSelect' );
      this.context = e;
      let el = document.getElementById('debugFind');
      switch( e ) {
        case 'localstorage':
        case 'indexeddb':
          el.style.display = '';
          el.value = '';

          // Reset the selection
          var els = document.querySelectorAll('#nav-' +e +' pre');
          els.forEach((e)=>{
            e.style.display = '';
          });
          break;
        default:
          el.style.display = 'none';
          break;
      }
    },

    resendPicture() {
      console.log('debug.resendPicture');
      try {
        if( confirm( 'Er du sikker?')) {
          switch( this.type ) {
            case 'indexeddb':
              var pictures = this.app.storage.get('app.pictures');
              pictures[this.key].status = 'local';
              this.app.storage.set('app.picture',pictures);
              break;
            default:
              alert( 'Unknown type; expected indexeddb' );
              break;
          }
          this.modal.hide();
        }
      } catch(error) {
        console.error(error);
      }
    },

    resendRecord() {
      console.log('debug.resendRecord');
      try {
        if( confirm( 'Er du sikker?')) {
          switch( this.type ) {
            case 'localstorage':
              var record = this.app.storage.get(this.key);
              console.log( 'resending ' +this.key +'...');
              record.status = 'local';
              this.app.storage.set(this.key,record);
              break;
            default:
              alert( 'Unknown type; expected localstorage' );
              break;
          }
          this.modal.hide();
        }
      } catch(error) {
        console.error(error);
      }
    }

  }
}
</script>